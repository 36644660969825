import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Office } from '@models/office.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OfficeWebservice {
  constructor(private readonly http: HttpClient) {}

  getAll(): Observable<Office[]> {
    return this.http.get<Office[]>(`${environment.api.organizationChart}/v1/office`);
  }

  getOne(officeId: string): Observable<Office> {
    return this.http.get<Office>(`${environment.api.organizationChart}/v1/office/${officeId}`);
  }
}

export enum EmployeeStatusIds {
  Published = 'published',
  Drafted = 'draft',
  Deleted = 'deleted',
}

export enum EmployeeStatusLabels {
  Published = 'Publié',
  Drafted = 'Brouillon',
  Deleted = 'Supprimé',
}

enum StatusName {
  Published = 'Published',
  Drafted = 'Drafted',
  Deleted = 'Deleted',
}

export interface EmployeeStatus {
  id: EmployeeStatusIds;
  label: EmployeeStatusLabels;
}

export const EmployeeStatuses: Record<StatusName, EmployeeStatus> = {
  Published: {
    id: EmployeeStatusIds.Published,
    label: EmployeeStatusLabels.Published,
  },
  Drafted: {
    id: EmployeeStatusIds.Drafted,
    label: EmployeeStatusLabels.Drafted,
  },
  Deleted: {
    id: EmployeeStatusIds.Deleted,
    label: EmployeeStatusLabels.Deleted,
  },
};

import { Injectable } from '@angular/core';
import { PhotoAlbumWithPhotos } from '@models/photo-album.interface';
import { Photo } from '@models/photo.interface';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import {
  ResetCurrentAlbum,
  ResetCurrentPhoto,
  SetCurrentPhoto,
  SetCurrentPhotoAlbum,
} from '@stores/photo-album/photo-album.actions';

export type PhotoAlbumStateModel = {
  currentPhotoAlbum: PhotoAlbumWithPhotos | null;
  currentPhoto: Photo | null;
  currentPhotoIndex: number;
  previousPhotoId: string | null;
  nextPhotoId: string | null;
};
export const PhotoAlbumStateToken = new StateToken<PhotoAlbumStateModel>('photoAlbum');

export type PhotoContext = Omit<PhotoAlbumStateModel, 'currentPhotoAlbum'> & {
  photoCount: number;
};

@State({
  name: PhotoAlbumStateToken,
  defaults: {
    currentPhotoAlbum: null,
    currentPhoto: null,
    currentPhotoIndex: -1,
    previousPhotoId: null,
    nextPhotoId: null,
  },
})
@Injectable()
export class PhotoAlbumState {
  @Selector([PhotoAlbumStateToken])
  static getCurrentPhotoAlbum({ currentPhotoAlbum }: PhotoAlbumStateModel): PhotoAlbumWithPhotos | null {
    return currentPhotoAlbum;
  }

  @Selector([PhotoAlbumStateToken])
  static getCurrentPhotoContext({
    currentPhotoAlbum,
    currentPhoto,
    currentPhotoIndex,
    previousPhotoId,
    nextPhotoId,
  }: PhotoAlbumStateModel): PhotoContext {
    return {
      currentPhoto,
      currentPhotoIndex,
      previousPhotoId,
      nextPhotoId,
      photoCount: currentPhotoAlbum?.photos.length ?? 0,
    };
  }

  @Action(SetCurrentPhotoAlbum)
  setCurrentPhotoAlbum(ctx: StateContext<PhotoAlbumStateModel>, { photoAlbum }: SetCurrentPhotoAlbum): any {
    return ctx.patchState({ currentPhotoAlbum: photoAlbum });
  }

  @Action(ResetCurrentAlbum)
  resetCurrentAlbum(ctx: StateContext<PhotoAlbumStateModel>): any {
    return ctx.patchState({
      currentPhotoAlbum: null,
    });
  }

  @Action(SetCurrentPhoto)
  setCurrentPhoto(
    ctx: StateContext<PhotoAlbumStateModel>,
    { photo, previousPhotoId, nextPhotoId, index }: SetCurrentPhoto
  ): any {
    return ctx.patchState({
      currentPhoto: photo,
      previousPhotoId,
      nextPhotoId,
      currentPhotoIndex: index,
    });
  }

  @Action(ResetCurrentPhoto)
  resetCurrentPhoto(ctx: StateContext<PhotoAlbumStateModel>): any {
    return ctx.patchState({
      currentPhoto: null,
      previousPhotoId: null,
      nextPhotoId: null,
    });
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginTokenGuard } from '@core/guards/login-token.guard';
import { QuicklinkSharedModule } from '@core/ngx-quicklink.shared.module';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { SharedModule } from '@shared/shared.module';
import { AUTHENTICATION_API_CONFIG } from '@wizbii/webservices';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoginTokenGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./home/home.component').then((module) => module.HomeModule),
      },
      {
        path: FeaturesRoutingEnum.Admin,
        loadChildren: () => import('./admin/admin.module').then((module) => module.AdminModule),
      },
      {
        path: FeaturesRoutingEnum.Search,
        loadChildren: () => import('./search/search.component').then((module) => module.SearchModule),
      },
      {
        path: FeaturesRoutingEnum.Chart,
        loadChildren: () => import('./chart/chart.module').then((module) => module.ChartModule),
      },
      {
        path: FeaturesRoutingEnum.Jobs,
        loadChildren: () => import('./jobs/jobs.module').then((module) => module.JobsModule),
      },
      {
        path: FeaturesRoutingEnum.Teams,
        loadChildren: () => import('./teams/teams.module').then((module) => module.TeamsModule),
      },
      {
        path: FeaturesRoutingEnum.PhotoAlbums,
        loadChildren: () => import('./photo-albums/photo-albums.routes').then((module) => module.PHOTO_ALBUMS_ROUTES),
      },
      { path: '**', redirectTo: '' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), QuicklinkSharedModule, SharedModule],
  providers: [
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
  ],
})
export class FeaturesModule {}

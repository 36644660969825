import { Injectable } from '@angular/core';
import { sortRoles } from '@commons/utils-roles';
import { EmployeeRoleRequest } from '@models/employee-role.interface';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { EmployeeFormsState } from '@stores/employee-form/employee-forms.state';
import {
  SetUpdatedEmployeeRoles,
  SetUpdatingEmployeeRoleIndex,
  UpdateEmployeeRole,
} from '@stores/employee-form/employee-roles.actions';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export interface EmployeeRolesStateModel {
  updatedRoles: EmployeeRoleRequest[] | undefined;
  updatingRoleIndex: number | null;
}

const EmployeeRolesStateToken = new StateToken<EmployeeRolesStateModel>('employeeRoles');

@State({
  name: EmployeeRolesStateToken,
  defaults: {
    updatedRoles: undefined,
    updatingRoleIndex: 0,
  },
})
@Injectable()
export class EmployeeRolesState {
  @Selector([EmployeeRolesState])
  static updatedRoles(state: EmployeeRolesStateModel): EmployeeRoleRequest[] | undefined {
    return state.updatedRoles;
  }

  @Selector([EmployeeRolesState])
  static updatedRoleIndex(state: EmployeeRolesStateModel): number | null {
    return state.updatingRoleIndex;
  }

  constructor(private readonly store: Store) {}

  @Action(SetUpdatingEmployeeRoleIndex)
  setUpdatedRoleIndex(
    ctx: StateContext<EmployeeRolesStateModel>,
    { updatingRoleIndex }: SetUpdatingEmployeeRoleIndex
  ): void {
    ctx.patchState({ updatingRoleIndex });
  }

  @Action(SetUpdatedEmployeeRoles)
  setUpdatedEmployeeRoles(ctx: StateContext<EmployeeRolesStateModel>, { updatedRoles }: SetUpdatedEmployeeRoles): void {
    ctx.setState({ updatedRoles, updatingRoleIndex: 0 });
  }

  @Action(UpdateEmployeeRole)
  updateEmployeeRole(ctx: StateContext<EmployeeRolesStateModel>): void {
    const updatedRoles = ctx.getState().updatedRoles;
    const index = ctx.getState().updatingRoleIndex;

    if (!updatedRoles) return;

    this._createUpdatedRole()
      .pipe(take(1))
      .subscribe({
        next: (updatedRole) => {
          if (!updatedRole) return;

          const newUpdatedRoles = [...updatedRoles];

          if (
            updatedRoles[0] &&
            new Date(updatedRoles[0].startDate) < new Date(updatedRole.startDate) &&
            !updatedRoles[0].endDate
          ) {
            newUpdatedRoles[0] = { ...newUpdatedRoles[0], endDate: updatedRole.startDate };
          }

          index !== null ? (newUpdatedRoles[index] = updatedRole) : newUpdatedRoles.unshift(updatedRole);

          ctx.patchState({ updatedRoles: sortRoles(newUpdatedRoles) });
        },
      });
  }

  private _createUpdatedRole(): Observable<EmployeeRoleRequest | null> {
    return combineLatest([
      this.store.select(EmployeeFormsState.teamsFormValues),
      this.store.select(EmployeeFormsState.careerFormValues),
    ]).pipe(
      take(1),
      map(([teamsFormValues, careerFormValues]) => {
        if (!careerFormValues) {
          return null;
        }

        const teamIds: string[] = [];
        if (teamsFormValues?.teamsIds) {
          teamIds.push(...teamsFormValues.teamsIds);
        }
        if (careerFormValues.teamIds) {
          teamIds.push(...careerFormValues.teamIds);
        }

        if (
          !careerFormValues.startDate ||
          !careerFormValues.jobId ||
          !careerFormValues.teamIds ||
          !careerFormValues.contract ||
          !careerFormValues.officeId ||
          !careerFormValues.managerId
        ) {
          return null;
        }

        return {
          startDate: careerFormValues.startDate,
          endDate: careerFormValues.endDate,
          jobId: careerFormValues.jobId,
          teamIds: [...new Set(teamIds)],
          contract: careerFormValues.contract,
          officeId: careerFormValues.officeId,
          managerId: careerFormValues.managerId,
        };
      })
    );
  }
}

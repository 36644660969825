import { Team } from '@models/team.interface';

export class AddTeam {
  static readonly type = '[Team] Add team';
  constructor(public team: Team) {}
}

export class ModifyTeam {
  static readonly type = '[Team] Modify team';
  constructor(public team: Team, public teamIdToModify: string) {}
}

export class DeleteTeam {
  static readonly type = '[Team] Delete team';
  constructor(public teamIdToDelete: string) {}
}

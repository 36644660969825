import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { AlgoliaJob, Job, JobEmployees } from '@models/job.interface';
import { AlgoliaClientWebservice } from '@webservices/organization-chart-api/algolia-client.webservice';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobWebservice extends AlgoliaClientWebservice {
  private readonly _http: HttpClient = inject(HttpClient);

  constructor() {
    super(environment.algolia.index.jobs);
  }

  getAll(): Observable<Job[]> {
    return this._http.get<Job[]>(`${environment.api.organizationChart}/v1/job/`);
  }

  getJobsAndMembers(jobIds: string[]): Observable<JobEmployees[]> {
    return jobIds.length > 0
      ? this._http.get<JobEmployees[]>(`${environment.api.organizationChart}/v1/job/${jobIds}/members`)
      : of([]);
  }

  getOne(jobId: string): Observable<Job> {
    return this._http.get<Job>(`${environment.api.organizationChart}/v1/job/${jobId}`);
  }

  createOne(job: Job): Observable<Job> {
    return this._http.post<Job>(`${environment.api.organizationChart}/v1/job/`, job);
  }

  modifyOne(job: Job): Observable<Job> {
    return this._http.put<Job>(`${environment.api.organizationChart}/v1/job/${job.id}`, {
      name: job.name,
      description: job.description,
    });
  }

  getJobsFromAlgolia(jobName: string, facetFilters: string[][]): Observable<AlgoliaJob[]> {
    return this.getHitsFromQuery(jobName, { facetFilters, hitsPerPage: 100 });
  }
}

import { Injectable } from '@angular/core';
import { EmployeeStatusIds } from '@models/employee-status.interface';
import { Employee } from '@models/employee.interface';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { UpdateEmployees } from '@stores/employees/employees.actions';
import { EmployeeWebservice } from '@webservices/organization-chart-api/employee.webservice';
import { JwtState } from '@wizbii/stores';
import { filter, switchMap, take } from 'rxjs/operators';

export type EmployeesStateModels = Employee[];

export const EmployeesStateToken = new StateToken<EmployeesStateModels>('employees');

@State({
  name: EmployeesStateToken,
  defaults: [],
})
@Injectable()
export class EmployeesState implements NgxsOnInit {
  @Selector([EmployeesStateToken])
  static employeesOptions(state: EmployeesStateModels): Record<string, string> {
    return state
      .filter((employee) => employee.status === EmployeeStatusIds.Published)
      .reduce(
        (acc, employee) => ({
          ...acc,
          [employee.id]: `${employee.personalInformation.firstName} ${employee.personalInformation.lastName}`,
        }),
        {}
      );
  }

  static getEmployeesByTeam(teamId: string): (employees: Employee[]) => Employee[] {
    return createSelector([EmployeesState], (employees) => {
      return employees.filter(
        (employee) =>
          employee.status === EmployeeStatusIds.Published &&
          employee.roles[0]?.teams.map((team) => team.id).includes(teamId)
      );
    });
  }

  constructor(private readonly employeeWebservice: EmployeeWebservice, private readonly store: Store) {}

  ngxsOnInit(ctx?: StateContext<EmployeesStateModels>): void {
    this._setEmployees(ctx);
  }

  @Action(UpdateEmployees)
  updateEmployees(ctx: StateContext<EmployeesStateModels>): void {
    this._setEmployees(ctx);
  }

  private _setEmployees(ctx?: StateContext<EmployeesStateModels>): void {
    this.store
      .select(JwtState.isLogged)
      .pipe(
        filter((isLogged) => isLogged),
        switchMap(() => this.employeeWebservice.getAll()),
        take(1)
      )
      .subscribe({
        next: (employees) => ctx?.setState(employees),
      });
  }
}

import { Job } from '@models/job.interface';

export class AddJob {
  static readonly type = '[Job] Add job';
  constructor(public job: Job) {}
}

export class ModifyJob {
  static readonly type = '[Job] Modify job';
  constructor(public job: Job, public jobIdToModify: string) {}
}

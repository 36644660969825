import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CoreModule } from '@core/core.module';
import { environment } from '@environment';
import { LocaleEnum } from '@wizbii/models';
import * as dayjs from 'dayjs';

if (environment.production) {
  enableProdMode();
}

import(`dayjs/locale/${environment.i18n.lang}`).then(() => {
  dayjs.locale(environment.i18n.lang);
});
registerLocaleData(localeFr, LocaleEnum.fr_FR);

platformBrowserDynamic()
  .bootstrapModule(CoreModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { sortRoles } from '@commons/utils-roles';
import { environment } from '@environment';
import { Employee, EmployeeRequest } from '@models/employee.interface';
import { AlgoliaClientWebservice } from '@webservices/organization-chart-api/algolia-client.webservice';
import { normalizeSync } from 'normalize-diacritics';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmployeeWebservice extends AlgoliaClientWebservice {
  private readonly _http: HttpClient = inject(HttpClient);

  constructor() {
    super(environment.algolia.index.employees);
  }

  getAll(): Observable<Employee[]> {
    return this._http
      .get<Employee[]>(`${environment.api.organizationChart}/v1/employee`)
      .pipe(
        map((employees) =>
          employees.sort((a, b) => a.personalInformation.firstName.localeCompare(b.personalInformation.firstName))
        )
      );
  }

  getOne(employeeId: string): Observable<Employee> {
    return this._http
      .get<Employee>(`${environment.api.organizationChart}/v1/employee/${employeeId}`)
      .pipe(map((employee) => ({ ...employee, roles: sortRoles(employee.roles) })));
  }

  createEmployee(employee: EmployeeRequest | Employee): Observable<Employee> {
    employee.personalInformation.mail =
      employee.personalInformation.mail ||
      normalizeSync(
        `${employee.personalInformation.firstName}.${employee.personalInformation.lastName}@wizbii.com`
          .toLowerCase()
          .replace(/\s+/g, '')
      );

    return this._createAccount$(
      employee.personalInformation.firstName,
      employee.personalInformation.lastName,
      employee.personalInformation.mail
    ).pipe(
      switchMap((account) =>
        this._http.post<Employee>(`${environment.api.organizationChart}/v1/employee`, {
          ...employee,
          id: account.userId,
        })
      )
    );
  }

  editEmployee(editedEmployee: EmployeeRequest | Employee): Observable<Employee> {
    return this._http.put<Employee>(
      `${environment.api.organizationChart}/v1/employee/${editedEmployee.id}`,
      editedEmployee
    );
  }

  private _createAccount$(firstName: string, lastName: string, username: string): Observable<{ userId: string }> {
    return this._http.post<{ userId: string }>(`${environment.api.account}/v1/employee/account`, {
      firstName,
      lastName,
      appId: 'chartii',
      username,
      password: '',
      locale: 'fr_FR',
      isSponsored: false,
      platform: 'b2c-galaxy',
    });
  }
}

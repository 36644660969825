import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as localeData from 'dayjs/plugin/localeData';
import * as LocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as utc from 'dayjs/plugin/utc';

export interface DayJsDateAdapterOptions {
  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how Angular Material components like DatePicker output dates.
   * {@default false}
   */
  useUtc?: boolean;
}

// InjectionToken for Dayjs date adapter to configure options.
export const MAT_DAYJS_DATE_ADAPTER_OPTIONS = new InjectionToken<DayJsDateAdapterOptions>(
  'MAT_DAYJS_DATE_ADAPTER_OPTIONS',
  {
    providedIn: 'root',
    factory: MAT_DAYJS_DATE_ADAPTER_OPTIONS_FACTORY,
  }
);

export function MAT_DAYJS_DATE_ADAPTER_OPTIONS_FACTORY(): DayJsDateAdapterOptions {
  return {
    useUtc: false,
  };
}

// Creates an array and fills it with values.
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

// Custom Date Adapter : Uses Dayjs Dates to allow Angular Material usage with Locales.
@Injectable()
export class DayjsDateAdapter extends DateAdapter<Date> {
  private localeData!: {
    firstDayOfWeek: number;
    longMonths: string[];
    shortMonths: string[];
    dates: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
  };

  lang = 'fr';

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) public dateLocale: string,
    @Optional() @Inject(MAT_DAYJS_DATE_ADAPTER_OPTIONS) private readonly options?: DayJsDateAdapterOptions
  ) {
    super();
    this.initializeParser(dateLocale);
  }

  async setLocale(locale: string): Promise<void> {
    super.setLocale(locale);

    this.lang = locale.split('-')[0];

    await import(`dayjs/locale/${this.lang}.js`);
    dayjs.locale(this.lang); // use locale globally

    const dayJsLocaleData = this._dayJs().localeData();
    this.localeData = {
      firstDayOfWeek: dayJsLocaleData.firstDayOfWeek(),
      longMonths: dayJsLocaleData.months(),
      shortMonths: dayJsLocaleData.monthsShort(),
      dates: range(31, (i) => this._dayJs(this.createDate(dayjs().year(), 0, i + 1)).format('D')),
      longDaysOfWeek: range(7, (i) => this._dayJs().set('day', i).format('dddd')),
      shortDaysOfWeek: dayJsLocaleData.weekdaysShort(),
      narrowDaysOfWeek: dayJsLocaleData.weekdaysMin(),
    };
  }

  getYear(date: Date): number {
    return this._dayJs(date).year();
  }

  getMonth(date: Date): number {
    return this._dayJs(date).month();
  }

  getDate(date: Date): number {
    return this._dayJs(date).date();
  }

  getDayOfWeek(date: Date): number {
    return this._dayJs(date).day();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return style === 'long' ? this.localeData.longMonths : this.localeData.shortMonths;
  }

  getDateNames(): string[] {
    return this.localeData.dates;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return this.localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this.localeData.shortDaysOfWeek;
    }
    return this.localeData.narrowDaysOfWeek;
  }

  getYearName(date: Date): string {
    return this._dayJs(date).format('YYYY');
  }

  getFirstDayOfWeek(): number {
    return this.localeData.firstDayOfWeek;
  }

  getNumDaysInMonth(date: Date): number {
    return this._dayJs(date).daysInMonth();
  }

  clone(date: Date): Date {
    return new Date(date);
  }

  createDate(year: number, month: number, date: number): Date {
    return this._dayJs().set('year', year).set('month', month).set('date', date).toDate();
  }

  today(): Date {
    return this._dayJs().toDate();
  }

  addCalendarYears(date: Date, years: number): Date {
    return this._dayJs(date).add(years, 'year').toDate();
  }

  addCalendarMonths(date: Date, months: number): Date {
    return this._dayJs(date).add(months, 'month').toDate();
  }

  addCalendarDays(date: Date, days: number): Date {
    return this._dayJs(date).add(days, 'day').toDate();
  }

  toIso8601(date: Date): string {
    return date.toISOString();
  }

  /**
   * Parses a date from a User-provided value. (Ex. 12/08/2021)
   * @param { any } value The value to parse.
   * @param { string } parseFormat The expected format of the value being parsed
   * @returns { Date | null } The parsed date.
   */
  parse(value: any, parseFormat: string): Date | null {
    return value ? this._dayJs(value, parseFormat).toDate() : null;
  }

  /**
   * Formats a date as a string according to the given format.
   * @param { Date } date The date to format
   * @param { string } displayFormat The format to use to display date as a string to the User
   * @returns { string } The formatted date string.
   */
  format(date: Date, displayFormat: string): string {
    if (!this.isValid(date)) {
      throw Error('DayjsDateAdapter: Cannot format invalid date.');
    }
    return this._dayJs(date).locale(this.lang).format(displayFormat);
  }

  /**
   * Attempts to deserialize a value to a valid date object. This is different from parsing in that
   * deserialize should only accept non-ambiguous, locale-independent formats (e.g. a ISO 8601
   * string). The default implementation does not allow any deserialization, it simply checks that
   * the given value is already a valid date object or null.
   * @param { any } value The value to be deserialized into a date object.
   * @returns { Date | null } The deserialized date object, either a valid date, null if the value can be deserialized into a null date (e.g. the empty string), or an invalid date.
   */
  deserialize(value: any): Date | null {
    if (value) {
      if (value instanceof Date) {
        return this.clone(value);
      }
      if (typeof value === 'string') {
        return this._dayJs(value).toDate();
      }
      if (typeof value === 'number') {
        return this._dayJs(value).toDate();
      }
      if (dayjs.isDayjs(value)) {
        return value.toDate();
      }
      return null;
    }
    return null;
  }

  isDateInstance(obj: any): boolean {
    return obj instanceof Date;
  }

  isValid(date: Date): boolean {
    return this._dayJs(date).isValid();
  }

  invalid(): Date {
    return this._dayJs(null).toDate();
  }

  private _dayJs(input?: any, format?: string): dayjs.Dayjs {
    const date = dayjs(input, format);

    if (!this.shouldUseUtc) {
      return date;
    }

    return date.utc();
  }

  private get shouldUseUtc(): boolean {
    const { useUtc }: DayJsDateAdapterOptions = this.options || {};
    return !!useUtc;
  }

  private initializeParser(dateLocale: string) {
    if (this.shouldUseUtc) {
      dayjs.extend(utc);
    }

    dayjs.extend(LocalizedFormat);
    dayjs.extend(customParseFormat);
    dayjs.extend(localeData);

    this.setLocale(dateLocale);
  }
}

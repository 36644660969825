/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getBuildEnvVar, getRuntimeEnvVar } from '@wizbii/angular-utilities';
import { buildConfig } from './build-config';

const packageJsonVersionAtBuild = require('../../package.json').version;

// @ts-ignore
const production: boolean = buildConfig === 'production';
const applicationId = 'chartii';
const apiDomain = getRuntimeEnvVar('API_DOMAIN');
const ciCommitSha = getBuildEnvVar('CI_COMMIT_SHA');
const platform = getRuntimeEnvVar('PLATFORM');
const apiPlatform = getRuntimeEnvVar('API_PLATFORM');
const wizbiiDomain = getRuntimeEnvVar('WIZBII_ENV_FQDN');
const wizbiiTeamDomain = getRuntimeEnvVar('WIZBII_TEAM_ENV_FQDN');
const deployAssetsUrl =
  production && ciCommitSha
    ? `https://storage.googleapis.com/wizbii-application-assets/chartii/${ciCommitSha}/assets/`
    : null;

const chartiiUrl = platform !== 'local' ? window.location.origin : 'https://localhost.0';

export const environment = {
  applicationId,
  platform,
  apiPlatform,
  apiDomain,
  isDeployed: ciCommitSha !== 'local',
  production,
  version: production ? packageJsonVersionAtBuild : getBuildEnvVar('GIT_BRANCH_NAME'),
  i18n: {
    lang: 'fr',
    locale: 'fr_FR',
  },
  bugsnag: {
    key: getRuntimeEnvVar('BUGSNAG_KEY'),
  },
  domain: {
    wizbii: wizbiiDomain,
    wizbiiTeam: wizbiiTeamDomain,
    cookie: window.location.hostname === 'localhost' ? 'localhost' : wizbiiTeamDomain,
  },
  urls: {
    sso: `https://sso.internal.${apiDomain}?redirect_url=${chartiiUrl}&appId=${applicationId}`,
    googleStorage: 'https://storage.googleapis.com',
  },
  api: {
    domain: apiDomain,
    authentication: `https://auth.${apiDomain}`,
    file: `https://file.${apiDomain}`,
    googleStorage: 'https://storage.googleapis.com',
    imaginary: `https://imaginary.${apiDomain}`,
    organizationChart: `https://organization-chart-api.${apiDomain}`,
    account: `https://account.api.${apiDomain}`,
  },
  deployAssetsUrl,
  wizbiiFiles: getRuntimeEnvVar('FILE_API_BUCKET'),
  algolia: {
    clientKeyId: 'organisation_chart',
    index: {
      employees: `${apiPlatform}_organisation_chart_employees`,
      teams: `${apiPlatform}_organisation_chart_teams`,
      jobs: `${apiPlatform}_organisation_chart_jobs`,
      photoAlbums: `${apiPlatform}_organisation_chart_photo_albums`,
    },
  },
};

if (!production) {
  import('zone.js/dist/zone-error' as any); // Included with Angular CLI.
}

import { ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { ActivationEnd, Event, PRIMARY_OUTLET, Router, Scroll } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { RoleTypes } from '@models/commons/role';
import { Store } from '@ngxs/store';
import { JwtStateSelectors } from '@stores/selectors/jwt-state.selectors';
import { NavigationAction, NavigationItem } from '@wizbii/angular-backoffice-ui';
import { JwtState } from '@wizbii/stores';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { filter, map, pairwise, take } from 'rxjs/operators';

interface MenuItem extends NavigationItem {
  label: string;
  icon: string;
  isImplemented: boolean;
}

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit {
  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled(): boolean {
    return !this.hoverEnabled;
  }

  currentRoute$ = new BehaviorSubject<FeaturesRoutingEnum>(FeaturesRoutingEnum.Search);

  env = environment.platform;

  menuItems$!: Observable<MenuItem[]>;

  allItems = [
    {
      route: `/${FeaturesRoutingEnum.Chart}`,
      label: 'Organigramme',
      icon: 'hierarchy',
      isImplemented: true,
    },
    {
      route: `/${FeaturesRoutingEnum.Search}`,
      label: 'Recherche',
      icon: 'search',
      isImplemented: true,
    },
    {
      route: `/${FeaturesRoutingEnum.Admin}`,
      label: this.store.selectSnapshot(JwtStateSelectors.hasAuthorizedRole(RoleTypes.ROLE_OFFICE_MANAGER))
        ? "Espace d'administration"
        : 'Mes informations',
      icon: 'person',
      isImplemented: true,
    },
    {
      route: `/${FeaturesRoutingEnum.Jobs}`,
      label: 'Fiches de poste',
      icon: 'document',
      isImplemented: true,
    },
    {
      route: `/${FeaturesRoutingEnum.Teams}`,
      label: 'Équipes',
      icon: 'persons',
      isImplemented: true,
    },
    {
      route: `/${FeaturesRoutingEnum.PhotoAlbums}`,
      label: 'Albums photos',
      icon: 'image',
      isImplemented: true,
    },
  ];

  constructor(private readonly router: Router, viewportScroller: ViewportScroller, private readonly store: Store) {
    this.scrollPositionRestoration(router, viewportScroller);
  }

  private scrollPositionRestoration(router: Router, viewportScroller: ViewportScroller): void {
    router.events
      .pipe(
        filter((e: Event) => e instanceof Scroll),
        map((e) => [e as Scroll, window.location.pathname]),
        take(1)
      )
      .subscribe({
        next: () => {
          viewportScroller.scrollToPosition([0, 0]);
        },
      });

    router.events
      .pipe(
        filter((e: Event) => e instanceof Scroll),
        map((e) => [e as Scroll, window.location.pathname]),
        pairwise()
      )
      .subscribe({
        next: ([[_, previousUrl], [currentEvent, currentUrl]]: any) => {
          if (currentEvent.position) {
            viewportScroller.scrollToPosition(currentEvent.position);
          } else if (currentEvent.anchor) {
            viewportScroller.scrollToAnchor(currentEvent.anchor);
          } else if (previousUrl !== currentUrl) {
            viewportScroller.scrollToPosition([0, 0]);
          }
        },
      });

    router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe({ next: () => this.setCurrentRoute(this.router) });
  }

  ngOnInit(): void {
    if (document.documentElement.lang === '') {
      document.documentElement.lang = environment.i18n.lang;
    }

    this.menuItems$ = this.store
      .select(JwtState.roles)
      .pipe(map(() => this.allItems.filter((item) => item.isImplemented)));

    /**
     * Disable hover on `touchstart` to cover browsers that do not support pointer events.
     * https://caniuse.com/#feat=pointer
     */
    fromEvent(window, 'touchstart', { passive: true }).subscribe({
      next: () => {
        this.hoverEnabled = false;
      },
    });
  }

  private setCurrentRoute(router: Router): void {
    const tree = router.parseUrl(router.url);
    const children = tree.root.children[PRIMARY_OUTLET];

    if (!children) {
      return this.currentRoute$.next(FeaturesRoutingEnum.Search);
    }

    const firstSegment = children.segments[0];

    if (this.isFeatureRoute(firstSegment.path)) {
      this.currentRoute$.next(firstSegment.path);
    }
  }

  private isFeatureRoute(path: any): path is FeaturesRoutingEnum {
    return Object.values(FeaturesRoutingEnum).includes(path);
  }

  handleItemClicked(item: NavigationItem | NavigationAction): void {
    if ((item as any)['route']) {
      this.router.navigate([(item as any)['route']], { state: { fromHome: true } });
    }
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event: any): void {
    this.hoverEnabled = event.pointerType === 'mouse';
  }

  trackByIcon(_: number, item: MenuItem): string {
    return item.icon;
  }
}

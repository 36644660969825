import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDayjsDateAdapterModule } from '@commons/dayjs-date-adapter/dayjs-date-adapter.module';
import { WzbButtonModule } from '@wizbii/angular-ui';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    WzbButtonModule,
    MatDialogModule,
    MatDayjsDateAdapterModule,
    MatDatepickerModule,
    MatAutocompleteModule,
  ],
  declarations: [],
  exports: [
    CommonModule,
    MatIconModule,
    WzbButtonModule,
    MatDialogModule,
    MatDayjsDateAdapterModule,
    MatDatepickerModule,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
})
export class SharedModule {}

import {
  CareerFormValues,
  DetailsFormValues,
  DocumentsFormValues,
  TeamsFormValues,
  WizFormValues,
} from '@models/employee-forms.interface';
import { Employee } from '@models/employee.interface';

export class SetOriginalEmployee {
  static readonly type = '[EmployeeForms] Set original employee';
  constructor(public originalEmployee?: Employee) {}
}

export class SetDetailsFormValues {
  static readonly type = '[DetailsForm] Set details form values';
  constructor(public model?: DetailsFormValues) {}
}

export class SetCareerFormValues {
  static readonly type = '[CareerForm] Set career form values';
  constructor(public model?: CareerFormValues, public resetDirtyStatus = true) {}
}

export class SetWizFormValues {
  static readonly type = '[WizForm] Set wiz form values';
  constructor(public model?: WizFormValues) {}
}

export class SetTeamsFormValues {
  static readonly type = '[TeamsForm] Set teams form values';
  constructor(public model?: TeamsFormValues) {}
}

export class SetDocumentsFormValues {
  static readonly type = '[DocumentsForm] Set documents form values';
  constructor(public model?: DocumentsFormValues) {}
}

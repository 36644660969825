import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NgZone } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@commons/dayjs-date-adapter/dayjs-date-adapter';
import { ngxsConfig } from '@config/ngxs.config';
import { routerConfig } from '@config/router.config';
import { CoreComponent } from '@core/components/core/core.component';
import { QuicklinkSharedModule } from '@core/ngx-quicklink.shared.module';
import { WizbiiRouterStateSerializer } from '@core/serializer/router.serializer';
import { JwtService } from '@core/services/jwt.service';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { EmployeeFormsState } from '@stores/employee-form/employee-forms.state';
import { EmployeeRolesState } from '@stores/employee-form/employee-roles.state';
import { EmployeesState } from '@stores/employees/employees.state';
import { JobState } from '@stores/jobs/jobs.state';
import { OfficeState } from '@stores/office/office.state';
import { PhotoAlbumState } from '@stores/photo-album/photo-album.state';
import { TeamState } from '@stores/team/team.state';
import { AlgoliaCredentialsWebservice } from '@webservices/organization-chart-api/algolia-credentials.webservice';
import { FILE_PICKER_SERVICE_TOKEN, FileWebService } from '@wizbii-components/bo-angular-ui';
import { ALGOLIA_CREDENTIALS_FETCHERS_TOKEN, Credentials, CredentialsFetchers } from '@wizbii/algolia';
import { BoNavigationSidebarMenuModule } from '@wizbii/angular-backoffice-ui';
import { NotificationsModule } from '@wizbii/angular-ui';
import { ImageService } from '@wizbii/angular-utilities';
import { JWT_SERVICE_TOKEN, JwtInterceptor } from '@wizbii/jwt';
import { JWT_STATE_CONFIG, JwtState } from '@wizbii/stores';
import {
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  FILE_API_CONFIG,
  FileWebservice as FileApiWebService,
} from '@wizbii/webservices';
import { Observable } from 'rxjs';

@NgModule({
  declarations: [CoreComponent],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,

    FeaturesModule,

    QuicklinkSharedModule,
    RouterModule.forRoot([], routerConfig),
    BoNavigationSidebarMenuModule,

    NgxsModule.forRoot(
      [
        JwtState,
        OfficeState,
        TeamState,
        JobState,
        EmployeesState,
        EmployeeFormsState,
        EmployeeRolesState,
        PhotoAlbumState,
      ],
      ngxsConfig
    ),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),

    NotificationsModule.forRoot(),
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },
    { provide: LOCALE_ID, useValue: environment.i18n.locale },
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: ImageService,
      useValue: new ImageService(environment.api.imaginary),
    },
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
    AuthenticationWebservice,

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },
    {
      provide: JWT_STATE_CONFIG,
      useValue: {
        jwtCookieName: 'wizbii_bo',
        expiryCookieName: 'wizbii_bo_expiry',
        appEnvFqdn: environment.domain.cookie,
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', hideRequiredMarker: true },
    },
    { provide: MAT_DATE_LOCALE, useValue: environment.i18n.locale.replace('_', '-') },
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.file,
        googleStorageUrl: environment.urls.googleStorage,
        wizbiiFilesBucket: environment.wizbiiFiles,
      },
    },
    {
      provide: FILE_PICKER_SERVICE_TOKEN,
      useClass: FileWebService,
    },
    FileApiWebService,
    {
      provide: ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
      useFactory: (algoliaCredentialsWebservice: AlgoliaCredentialsWebservice): CredentialsFetchers => {
        const organisationChartCredentials = {
          fetchCredentials(): Observable<Credentials> {
            return algoliaCredentialsWebservice.fetchCredentials();
          },
        };

        return {
          organisation_chart: organisationChartCredentials,
        };
      },
      deps: [AlgoliaCredentialsWebservice],
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [CoreComponent],
})
export class CoreModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, ngZone: NgZone) {
    ngZone.runOutsideAngular(() => {
      const domain = environment.deployAssetsUrl ?? '/assets/';
      matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl(`${domain}sprite.svg`));
    });
  }
}

import { MatDateFormats } from '@angular/material/core';

const dateInputParseFormats: string[] = ['D/MM/YYYY', 'DD/MM/YYYY', 'D/MM/YY', 'DD/MM/YY'];

export const MAT_DAYJS_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: dateInputParseFormats,
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DDD LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import { Injectable } from '@angular/core';
import { Job } from '@models/job.interface';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { AddJob, ModifyJob } from '@stores/jobs/job.actions';
import { JobWebservice } from '@webservices/organization-chart-api/job.webservice';
import { JwtState } from '@wizbii/stores';
import { filter, switchMap, take } from 'rxjs/operators';

export type JobStateModels = Job[];
export const JobStateToken = new StateToken<JobStateModels>('jobs');

@State({
  name: JobStateToken,
  defaults: [],
})
@Injectable()
export class JobState implements NgxsOnInit {
  @Selector([JobStateToken])
  static jobsOptions(state: JobStateModels): Record<string, string> {
    return state.reduce((acc, job) => ({ ...acc, [job.id]: job.name }), {});
  }

  @Selector([JobStateToken])
  static getAll(state: JobStateModels): Job[] {
    return state;
  }

  constructor(private readonly jobWebservice: JobWebservice, private readonly store: Store) {}

  ngxsOnInit(ctx?: StateContext<JobStateModels>): void {
    this.store
      .select(JwtState.isLogged)
      .pipe(
        filter((isLogged) => isLogged),
        switchMap(() => this.jobWebservice.getAll()),
        take(1)
      )
      .subscribe({
        next: (jobs) => {
          ctx?.setState(jobs);
        },
      });
  }

  @Action(AddJob)
  addJob(ctx: StateContext<JobStateModels>, action: AddJob): any {
    const state = ctx.getState();
    return ctx.setState(state.concat([action.job]));
  }

  @Action(ModifyJob)
  modifyJob(ctx: StateContext<JobStateModels>, action: ModifyJob): any {
    const state = [...ctx.getState()];
    state[state.findIndex((job) => job.id === action.jobIdToModify)] = action.job;
    return ctx.setState(state);
  }
}

<main class="container">
  <ng-container *ngIf="menuItems$ | async as menuItems">
    <wzb-bo-navigation-sidebar-menu
      *ngIf="currentRoute$ | async as currentRoute"
      [reduced]="true"
      [reducible]="false"
      [items]="menuItems"
      [activeRoute]="'/' + currentRoute"
      (actionClicked)="handleItemClicked($event)"
      (itemClicked)="handleItemClicked($event)"
    >
      <a menuLogo routerLink="/">
        <mat-icon svgIcon="my-wizbii-logo" class="sidebar-logo"></mat-icon>
      </a>

      <ng-container *ngFor="let item of menuItems; trackBy: trackByIcon">
        <mat-icon *wzbBoNavigationSidebarMenuIcon="item.icon" [svgIcon]="item.icon"></mat-icon>
      </ng-container>

      <wiz-sidebar menuOption [environment]="env"></wiz-sidebar>
    </wzb-bo-navigation-sidebar-menu>
  </ng-container>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</main>

<wzb-notifications></wzb-notifications>

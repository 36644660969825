import { ExtraOptions } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';

export const routerConfig: ExtraOptions = {
  initialNavigation: 'enabledNonBlocking',
  onSameUrlNavigation: 'reload',
  paramsInheritanceStrategy: 'always',
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  preloadingStrategy: QuicklinkStrategy,
  relativeLinkResolution: 'legacy',
};

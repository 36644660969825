import { environment } from '@environment';
import { NgxsModuleOptions, NoopNgxsExecutionStrategy } from '@ngxs/store';

export const ngxsConfig: NgxsModuleOptions = {
  developmentMode: !environment.production,
  executionStrategy: NoopNgxsExecutionStrategy,
  compatibility: {
    strictContentSecurityPolicy: true,
  },
  selectorOptions: {
    suppressErrors: false,
    injectContainerState: false,
  },
};

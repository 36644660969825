import { Injectable } from '@angular/core';
import { Team, TeamTypeEnum } from '@models/team.interface';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { AddTeam, DeleteTeam, ModifyTeam } from '@stores/team/team.actions';
import { TeamWebservice } from '@webservices/organization-chart-api/team.webservice';
import { JwtState } from '@wizbii/stores';
import { filter, switchMap, take } from 'rxjs/operators';

export type TeamStateModel = Team[];
export const TeamStateToken = new StateToken<TeamStateModel>('teams');

@State({
  name: TeamStateToken,
  defaults: [],
})
@Injectable()
export class TeamState implements NgxsOnInit {
  static team(teamId: string): (teams: Team[]) => Team | undefined {
    return createSelector([TeamState], (teams: Team[]) => {
      return teams.find((team) => team.id === teamId);
    });
  }

  static getTeams(teamIds: string[]): (teams: Team[]) => Team[] {
    return createSelector([TeamState], (teams: Team[]) => teams.filter((team) => teamIds.includes(team.id)));
  }

  @Selector([TeamStateToken])
  static hierarchicalTeams(state: TeamStateModel): Record<string, string> {
    return state
      .filter((team) => team.type === TeamTypeEnum.Hierarchical)
      .reduce((acc, team) => ({ ...acc, [team.id]: team.name }), {});
  }

  @Selector([TeamStateToken])
  static crossTeams(state: TeamStateModel): Team[] {
    return state.filter((team) => team.type === TeamTypeEnum.Cross);
  }

  constructor(private readonly teamWebservice: TeamWebservice, private readonly store: Store) {}

  ngxsOnInit(ctx?: StateContext<TeamStateModel>): void {
    this.store
      .select(JwtState.isLogged)
      .pipe(
        filter((isLogged) => isLogged),
        switchMap(() => this.teamWebservice.getAll()),
        take(1)
      )
      .subscribe({
        next: (teams) => {
          ctx?.setState(teams);
        },
      });
  }

  @Action(AddTeam)
  addTeam(ctx: StateContext<TeamStateModel>, action: AddTeam): any {
    const state = ctx.getState();
    return ctx.setState(state.concat([action.team]));
  }

  @Action(ModifyTeam)
  modifyTeam(ctx: StateContext<TeamStateModel>, action: ModifyTeam): any {
    const state = [...ctx.getState()];
    state[state.findIndex((team) => team.id === action.teamIdToModify)] = action.team;
    return ctx.setState(state);
  }

  @Action(DeleteTeam)
  deleteTeam(ctx: StateContext<TeamStateModel>, action: DeleteTeam): any {
    const state = ctx.getState();
    return ctx.setState(state.filter((team) => team.id !== action.teamIdToDelete));
  }
}

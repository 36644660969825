import { PhotoAlbumWithPhotos } from '@models/photo-album.interface';
import { Photo } from '@models/photo.interface';

export class SetCurrentPhotoAlbum {
  static readonly type = '[Photo Album] Set current photo album';
  constructor(public photoAlbum: PhotoAlbumWithPhotos) {}
}

export class ResetCurrentAlbum {
  static readonly type = '[Photo Album] Reset current album';
}

export class SetCurrentPhoto {
  static readonly type = '[Photo Album] Set current photo';
  constructor(
    public photo: Photo,
    public previousPhotoId: string | null,
    public nextPhotoId: string | null,
    public index: number
  ) {}
}

export class ResetCurrentPhoto {
  static readonly type = '[Photo Album] Reset current photo';
}

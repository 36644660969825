import { EmployeeRole, EmployeeRoleRequest } from '@models/employee-role.interface';

export const sortRoles = <T extends EmployeeRole | EmployeeRoleRequest>(employeeRoles: T[]): T[] => {
  return employeeRoles.slice().sort((a, b) => {
    if (a.endDate && !b.endDate) return 1;
    if (!a.endDate && b.endDate) return -1;
    if (!a.endDate || !b.endDate) return -1;

    if (a.endDate && b.endDate) {
      if (new Date(a.endDate) < new Date(b.endDate)) return 1;
      if (new Date(a.endDate) > new Date(b.endDate)) return -1;
    }

    return new Date(a.startDate) < new Date(b.startDate) ? 1 : -1;
  });
};

export const findRoleIndex = (roles: EmployeeRoleRequest[], searchedRole: EmployeeRoleRequest): number | null => {
  let index = 0;
  let role: EmployeeRoleRequest | undefined;

  for (let i = 0; i < roles.length && !role; i++) {
    if (
      roles[index].contract !== searchedRole.contract ||
      roles[index].jobId !== searchedRole.jobId ||
      roles[index].managerId !== searchedRole.managerId ||
      roles[index].officeId !== searchedRole.officeId ||
      roles[index].startDate !== searchedRole.startDate ||
      roles[index].endDate !== searchedRole.endDate
    ) {
      index++;
    } else {
      role = roles[index];
      break;
    }
  }

  return role ? index : null;
};

import { EmployeeRoleRequest } from '@models/employee-role.interface';

export class SetUpdatedEmployeeRoles {
  static readonly type = '[EmployeeRoles] Set updated employee roles';
  constructor(public updatedRoles: EmployeeRoleRequest[]) {}
}

export class UpdateEmployeeRole {
  static readonly type = '[EmployeeRoles] Update employee role';
}

export class SetUpdatingEmployeeRoleIndex {
  static readonly type = '[EmployeeRoles] Set updated employee roles index';
  constructor(public updatingRoleIndex: number | null) {}
}

import { Injectable } from '@angular/core';
import { Office } from '@models/office.interface';
import { NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { OfficeWebservice } from '@webservices/organization-chart-api/office.webservice';
import { JwtState } from '@wizbii/stores';
import { filter, switchMap, take } from 'rxjs/operators';

export type OfficeStateModels = Office[];
export const OfficeStateToken = new StateToken<OfficeStateModels>('offices');

@State({
  name: OfficeStateToken,
  defaults: [],
})
@Injectable()
export class OfficeState implements NgxsOnInit {
  @Selector([OfficeStateToken])
  static offices(state: OfficeStateModels): Office[] {
    return state;
  }

  @Selector([OfficeStateToken])
  static officesOptions(state: OfficeStateModels): Record<string, string> {
    return state.reduce((acc, office) => ({ ...acc, [office.id]: office.name }), {});
  }

  constructor(private readonly officeWebservice: OfficeWebservice, private readonly store: Store) {}

  ngxsOnInit(ctx?: StateContext<OfficeStateModels>): void {
    this.store
      .select(JwtState.isLogged)
      .pipe(
        filter((isLogged) => isLogged),
        switchMap(() => this.officeWebservice.getAll()),
        take(1)
      )
      .subscribe({
        next: (offices) => {
          ctx?.setState(offices);
        },
      });
  }
}

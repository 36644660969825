import { Hit } from '@algolia/client-search';
import { Employee } from '@models/employee.interface';

interface BaseTeam {
  name: string;
  description: string | null;
  managerId: string | null;
  iconFileId: string | null;
  type: TeamTypeEnum;
  slackChannels: string[];
}

export interface Team extends BaseTeam {
  id: string;
  mailingList: string | null;
  dateCreated: string;
  dateModified: string;
}

export interface TeamRequest extends BaseTeam {
  membersIds: string[];
}

export interface TeamEmployees extends Team {
  teamMembers: Employee[];
}

export type AlgoliaTeam = Hit<Pick<Team, 'name' | 'mailingList'>>;

export enum TeamTypeEnum {
  Hierarchical = 'hierarchical',
  Cross = 'transverse',
}

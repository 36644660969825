import { Injectable } from '@angular/core';
import {
  CareerFormValues,
  DetailsFormValues,
  DocumentsFormValues,
  TeamsFormValues,
  WizFormValues,
} from '@models/employee-forms.interface';
import { EmployeeStatusIds } from '@models/employee-status.interface';
import { Employee } from '@models/employee.interface';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import {
  SetCareerFormValues,
  SetDetailsFormValues,
  SetDocumentsFormValues,
  SetOriginalEmployee,
  SetTeamsFormValues,
  SetWizFormValues,
} from '@stores/employee-form/employee-forms.actions';

export interface EmployeeForms {
  originalEmployee: Employee | undefined;
  detailsForm: DetailsForm | undefined;
  careerForm: CareerForm | undefined;
  wizForm: WizForm | undefined;
  teamsForm: TeamsForm | undefined;
  documentsForm: DocumentsForm | undefined;
}

enum FormStatusesEnum {
  Valid = 'VALID',
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Disabled = 'DISABLED',
}

interface FormStatus {
  dirty: boolean;
  status: FormStatusesEnum;
}

export interface DetailsForm extends FormStatus {
  model: DetailsFormValues | undefined;
}
export interface CareerForm extends FormStatus {
  model: CareerFormValues | undefined;
}
export interface WizForm extends FormStatus {
  model: WizFormValues | undefined;
}
export interface TeamsForm extends FormStatus {
  model: TeamsFormValues | undefined;
}
export interface DocumentsForm extends FormStatus {
  model: DocumentsFormValues | undefined;
}

const EmployeeFormsStateToken = new StateToken<EmployeeForms>('employeeForms');

@State({
  name: EmployeeFormsStateToken,
})
@Injectable()
export class EmployeeFormsState {
  @Selector([EmployeeFormsState])
  static originalEmployee(state: EmployeeForms): Employee | undefined {
    return state.originalEmployee;
  }

  @Selector([EmployeeFormsState])
  static formsHasBeenEdited(state: EmployeeForms): boolean {
    return (
      state.detailsForm?.dirty ||
      state.careerForm?.dirty ||
      state.wizForm?.dirty ||
      state.teamsForm?.dirty ||
      state.documentsForm?.dirty ||
      false
    );
  }

  @Selector([EmployeeFormsState])
  static formsAreDisabled(state: EmployeeForms): boolean {
    return state.originalEmployee?.status === EmployeeStatusIds.Deleted;
  }

  @Selector([EmployeeFormsState])
  static isNotSavable(state: EmployeeForms): boolean {
    if (!this.detailsFormValidity(state) || !this.formsHasBeenEdited(state)) {
      return true;
    }

    return !this.careerFormDirty(state) || (this.careerFormDirty(state) && this.careerFormValidity(state))
      ? false
      : true;
  }

  @Selector([EmployeeFormsState])
  static isNotPublishable(state: EmployeeForms): boolean {
    if (!this.detailsFormValidity(state)) {
      return true;
    }

    return this.careerFormValues(state) && this.careerFormValidity(state) ? false : true;
  }

  @Selector([EmployeeFormsState])
  static detailsFormValues(state: EmployeeForms): DetailsFormValues | undefined {
    return state.detailsForm?.model;
  }

  @Selector([EmployeeFormsState])
  static detailsFormValidity(state: EmployeeForms): boolean {
    return state.detailsForm
      ? state.detailsForm.status === FormStatusesEnum.Valid || state.detailsForm.status === FormStatusesEnum.Disabled
      : true;
  }

  @Selector([EmployeeFormsState])
  static careerFormValues(state: EmployeeForms): CareerFormValues | undefined {
    return state.careerForm?.model;
  }

  @Selector([EmployeeFormsState])
  static careerFormValidity(state: EmployeeForms): boolean {
    return state.careerForm
      ? state.careerForm.status === FormStatusesEnum.Valid || state.careerForm.status === FormStatusesEnum.Disabled
      : true;
  }

  @Selector([EmployeeFormsState])
  static careerFormDirty(state: EmployeeForms): boolean {
    return state.careerForm?.dirty || false;
  }

  @Selector([EmployeeFormsState])
  static wizFormValues(state: EmployeeForms): WizFormValues | undefined {
    return state.wizForm?.model;
  }

  @Selector([EmployeeFormsState])
  static documentsFormValues(state: EmployeeForms): DocumentsFormValues | undefined {
    return state.documentsForm?.model;
  }

  @Selector([EmployeeFormsState])
  static teamsFormValues(state: EmployeeForms): TeamsFormValues | undefined {
    return state.teamsForm?.model;
  }

  @Action(SetOriginalEmployee)
  setOriginalEmployee(ctx: StateContext<EmployeeForms>, { originalEmployee }: SetOriginalEmployee): void {
    ctx.patchState({ originalEmployee });
  }

  @Action(SetDetailsFormValues)
  setDetailsFormValues(ctx: StateContext<EmployeeForms>, { model }: SetDetailsFormValues): void {
    ctx.patchState({
      detailsForm: { model, status: model ? FormStatusesEnum.Valid : FormStatusesEnum.Invalid, dirty: false },
    });
  }

  @Action(SetCareerFormValues)
  setCareerFormValues(ctx: StateContext<EmployeeForms>, { model, resetDirtyStatus }: SetCareerFormValues): void {
    const status =
      model && model.contract && model.jobId && model.managerId && model.officeId && model.startDate && model.teamIds
        ? FormStatusesEnum.Valid
        : FormStatusesEnum.Invalid;

    let dirty = ctx.getState().careerForm?.dirty;
    if (dirty === undefined || resetDirtyStatus) {
      dirty = false;
    }

    ctx.patchState({
      careerForm: { model, status, dirty },
    });
  }

  @Action(SetWizFormValues)
  SetWizFormValues(ctx: StateContext<EmployeeForms>, { model }: SetWizFormValues): void {
    ctx.patchState({
      wizForm: { model, status: FormStatusesEnum.Valid, dirty: false },
    });
  }

  @Action(SetDocumentsFormValues)
  SetDocumentsFormValues(ctx: StateContext<EmployeeForms>, { model }: SetDocumentsFormValues): void {
    ctx.patchState({
      documentsForm: { model, status: FormStatusesEnum.Valid, dirty: false },
    });
  }

  @Action(SetTeamsFormValues)
  SetTeamsFormValues(ctx: StateContext<EmployeeForms>, { model }: SetTeamsFormValues): void {
    ctx.patchState({
      teamsForm: { model, status: FormStatusesEnum.Valid, dirty: false },
    });
  }
}

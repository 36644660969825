import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { AlgoliaTeam, Team, TeamEmployees, TeamRequest } from '@models/team.interface';
import { AlgoliaClientWebservice } from '@webservices/organization-chart-api/algolia-client.webservice';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamWebservice extends AlgoliaClientWebservice {
  private readonly _http: HttpClient = inject(HttpClient);

  constructor() {
    super(environment.algolia.index.teams);
  }

  getAll(): Observable<Team[]> {
    return this._http.get<Team[]>(`${environment.api.organizationChart}/v1/team`);
  }

  getTeamsAndMembers(teamIds: string[]): Observable<TeamEmployees[]> {
    return teamIds.length > 0
      ? this._http.get<TeamEmployees[]>(`${environment.api.organizationChart}/v1/team/${teamIds}/members`)
      : of([]);
  }

  create(team: TeamRequest): Observable<TeamEmployees> {
    return this._http.post<TeamEmployees>(`${environment.api.organizationChart}/v1/team?source=team`, team);
  }

  createWhenEmployee(team: TeamRequest): Observable<Team> {
    return this._http.post<Team>(`${environment.api.organizationChart}/v1/team?source=employee`, team);
  }

  edit(teamId: string, team: TeamRequest): Observable<TeamEmployees> {
    return this._http.put<TeamEmployees>(`${environment.api.organizationChart}/v1/team/${teamId}`, team);
  }

  delete(teamId: string): Observable<void> {
    return this._http.delete<void>(`${environment.api.organizationChart}/v1/team/${teamId}`);
  }

  getTeamsFromAlgolia(teamName: string): Observable<AlgoliaTeam[]> {
    return this.getHitsFromQuery(teamName, { hitsPerPage: 100 });
  }
}

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { environment } from '@environment';
import { Store } from '@ngxs/store';
import { JwtTokens } from '@wizbii/jwt';
import { JWT_STATE_CONFIG, JwtSet, JwtState, JwtStateConfigInterface } from '@wizbii/stores';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginTokenGuard implements CanActivate {
  constructor(
    readonly store: Store,
    readonly authService: AuthenticationWebservice,
    private readonly router: Router,
    private readonly cookieService: CookieService,
    @Inject(JWT_STATE_CONFIG) private readonly jwtStateConfig: JwtStateConfigInterface
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    const isLogged = !!this.readTokens();
    const loginToken = route.queryParamMap.get('login-token');

    if (isLogged) {
      return true;
    }

    if (!loginToken) {
      window.open(environment.urls.sso, '_self');
      return false;
    }

    return this.authService.getJwtFromLoginToken(loginToken).pipe(
      switchMap((tokens) => this.store.dispatch(new JwtSet(tokens))),
      switchMap(() => this.store.select(JwtState.isLogged)),
      map((_isLogged) => (_isLogged ? this.router.parseUrl('/') : false)),
      catchError(() => {
        return of(false);
      })
    );
  }

  readTokens(): JwtTokens | null {
    const rawTokens = JSON.parse(this.cookieService.get(this.jwtStateConfig.jwtCookieName) || 'null');
    return rawTokens ? rawTokens : null;
  }
}

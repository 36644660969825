import { Hit, SearchOptions, SearchResponse } from '@algolia/client-search';
import { inject } from '@angular/core';
import { environment } from '@environment';
import { AlgoliaClientProvider } from '@wizbii/algolia';
import { SearchIndex } from 'algoliasearch';
import { map, Observable, switchMap } from 'rxjs';

export abstract class AlgoliaClientWebservice {
  protected readonly algoliaClientProvider: AlgoliaClientProvider = inject(AlgoliaClientProvider);

  constructor(protected readonly algoliaIndexName: string) {}

  protected getAlgoliaIndex(): Observable<SearchIndex> {
    return this.algoliaClientProvider
      .getClient(environment.algolia.clientKeyId)
      .pipe(map((it) => it.initIndex(this.algoliaIndexName)));
  }

  public search<T extends Hit<unknown>>(query: string, searchOptions?: SearchOptions): Observable<SearchResponse<T>> {
    return this.getAlgoliaIndex().pipe(
      switchMap((index) => index.search<T>(query, { ...searchOptions, cacheable: false }))
    );
  }

  public getHitsFromQuery<T extends Hit<unknown>>(query: string, searchOptions?: SearchOptions): Observable<T[]> {
    return this.search<T>(query, searchOptions).pipe(map((searchResponse) => searchResponse.hits));
  }
}
